<template>
  <component :is="locationsData === undefined ? 'div' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="locationsData === undefined"
    >
      <h4 class="alert-heading">
        Error bij het ophalen van de locatie
      </h4>
      <div class="alert-body">
        Geen locatie gevonden voor deze id.
        <b-link
          class="alert-link"
          :to="{ name: 'app-locations-list'}"
        >
          Locatielijst
        </b-link>
        Voor andere locaties
      </div>
    </b-alert>
    <template #title>
      <feather-icon
        icon="UserIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Informatie</span>
    </template>

    <template v-if="locationsData">
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="mb-2"
          @submit.prevent="handleSubmit(updateOrganisation)"
        >
          <b-card>

            <div class="d-flex mb-1">
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Locatie
              </h4>
            </div>
            <b-row>

              <!-- Field: ID -->
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="ID"
                  label-for="id"
                >
                  <b-form-input
                    id="id"
                    v-model="locationsData.id"
                    readonly="readonly"
                  />
                </b-form-group>
              </b-col>
              <!-- Field: Name -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="name"
                  rules="required"
                >
                  <b-form-group
                    label="Naam"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="locationsData.name"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Bedrijf"
                  label-for="company"
                >
                  <v-select
                    v-model="locationsData.companyID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="organisationOptions"
                    value="id"
                    label="name"
                    :reduce="organisation => organisation.id"
                    :clearable="false"
                    input-id="organisations"
                    disabled="disabled"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-card>
                <b-row>
                  <b-col
                    cols="6 text-left"
                  >
                    <b-button
                      variant="secondary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="button"
                      :to="{ name: 'app-locations-list' }"
                    >
                      <span>Terug</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="6 text-right"
                  >
                    <b-button
                      v-if="$can('update', 'location')"
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="submit"
                    >
                      <span>Opslaan</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>

  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { required } from '@validations'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import locationsStoreModule from '@/views/pages/locations/locationsStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  components: {
    BButton,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BRow,
    BCol,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      locationsData: {
        id: null,
        name: '',
        companyID: '',
      },
      organisationOptions: [],
    }
  },
  mounted() {
    store.dispatch('app-locations-edit/fetchLocation', { id: router.currentRoute.params.id })
      .then(response => {
        this.locationsData = response.data
      })
      .catch(() => {
        this.locationsData = undefined
      })
  },
  beforeMount() {
    this.getOrganisations()
  },
  methods: {
    getOrganisations() {
      store
        .dispatch('app-locations-edit/fetchOrganisations', {
          sortBy: 'name',
          sortDesc: false,
        })
        .then(response => {
          this.organisationOptions = response.data.items
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen organisaties',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `${errorDefaultMessage.fetchDataError}`,
            },
          })
        })
    },
    updateOrganisation() {
      this.locationsData.id = router.currentRoute.params.id
      store.dispatch('app-locations-edit/updateLocation', this.locationsData)
        .then(() => {
          router.push({ name: 'app-locations-list' })
        })
    },
  },
  setup() {
    const LOCATIONS_EDIT_APP_STORE_MODULE_NAME = 'app-locations-edit'

    // Register module
    if (!store.hasModule(LOCATIONS_EDIT_APP_STORE_MODULE_NAME)) store.registerModule(LOCATIONS_EDIT_APP_STORE_MODULE_NAME, locationsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LOCATIONS_EDIT_APP_STORE_MODULE_NAME)) store.unregisterModule(LOCATIONS_EDIT_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      avatarText,
      required,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
